<template>
  <div class="guest-layout min-h-svh">
    <div class="min-h-svh mx-auto px-3 py-5">
      <slot />
    </div>
    <Footer />
  </div>
</template>

<style>
.guest-layout {
  background: rgb(238, 174, 202);
  background: linear-gradient(
    245deg,
    rgb(180 255 146 / 71%) 0%,
    rgb(0 58 237) 100%
  );
}
</style>
